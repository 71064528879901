import { useMutation } from "@apollo/client";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";
import { CONFIRM_EMAIL } from "../../queries/auth";
import GetHelpModal from "../../components/Modal/GetHelpModal";

import "./confirmEmail.scss";

const ConfirmEmailPage: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  
  const [confirmEmail, { data, loading, error }] = useMutation(CONFIRM_EMAIL, {
    variables: {
      token,
    },
  });

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  React.useEffect(() => {
    confirmEmail();
  }, []);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg="5" md="7">
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 py-lg-5">
              {loading ? (
                <>
                  <h1 className="confirmEmail-text">Email Confirmation...</h1>
                  <Spinner animation="border" variant="info" />
                </>
              ) : error ? (
                <>
                  <h1 className="confirmEmail-text">{error.message}</h1>
                  <p className="confirmEmail-description">
                    Your password is reset, and you’re back in the game! Log in now and get back to the fun.
                  </p>
                </>
              ) : (
                <>
                  <h1 className="confirmEmail-text">You’re All Set!</h1>
                  <p className="confirmEmail-description">
                    Your password is reset, and you’re back in the game! Log in now and get back to the fun.
                  </p>
                </>
              )}
              <div className="divider-red"></div>
              {!loading ? (
                <>
                  <Row className="d-flex justify-content-center">
                    <Link to="/login" className="my-2 bg-confirmEmail-btn text-center">
                      Log In
                    </Link>
                  </Row>
                  <Row className="d-flex justify-content-center">
                    <p className="link-paragraph">
                      Didn’t request this change?
                      <a onClick={() => setIsModalOpen(true)}>
                        <small>Contact Support</small>
                      </a>
                    </p>
                  </Row>
                </>
              ) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <GetHelpModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        tag="vipgetintouch"
      />
    </Container>
  );
};

export default ConfirmEmailPage;

import { Navbar, Container } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { checkToken, getUserRole } from "../../utils/services";
import "./header.scss"
import loginSvg from "../../assets/img/icons/common/login.svg";

const JWT_KEY = "jwt";

const Header: React.FC = () => {
  const history = useHistory();

  const logout = () => {
    document.cookie = `${JWT_KEY}=; Path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

    history.push("/login");
  };

  return (
    <Navbar
    className="header-container py-3"
      // color={getUserRole() === "BROKER" ? "default" : "white"}
      // className={`navbar-top navbar-expand navbar-light bg-secondary pt-3 ${
      //   getUserRole() === "BROKER" ? "" : "pb-0"
      // }`}
    >
      <Container fluid style={{ display: "flex", alignItems: "center" }}>
        <h2
          style={{
            fontWeight: "700",
            fontSize: "32px",
            lineHeight: "38px",
            display: "flex",
            alignItems: "center",
            color: getUserRole() === "BROKER" ? "#ffffff" : "#1B204C",
            margin: "0",
          }}
        >
        </h2>
        {checkToken() ? (
          <button
            onClick={(e: any) => {
              logout();
            }}
            style={{
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "20px",
              color: "#ffffff",
              // color: getUserRole() === "BROKER" ? "#ffffff" : "#000000",
              border: "none",
              background: "none",
            }}
          > 
          <div className="logout-container">
            <img className="mr-2" src={loginSvg} width="18" height="18" alt="login-img" />
            {/* <i className="fa fa-xl fa-solid fa-right-from-bracket mr-2"></i> */}
            <span>Sign out</span>
          </div>
          </button>
        ) : (
          <div className="login-container">
            <img 
              src={loginSvg} 
              width="18" 
              height="18" 
              alt="login-img" 
              onClick={() => history.push("/register")} 
              style={{ cursor: "pointer" }} 
            />
          </div>
        )}
      </Container>
    </Navbar>
  );
};

export default Header;

import { useApolloClient } from "@apollo/client";
import { Formik } from "formik";
import { gapi } from "gapi-script";
import React, { useState } from "react";
import GoogleLogin from "react-google-login";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { errorMsg } from "../../cache/vars";
import { useRoleContext } from "../../Navigator";
import { GOOGLE_SIGN_UP, USER_SIGN_UP } from "../../queries/auth";

import eyeIcon from "../../assets/img/icons/cards/eye-icon.svg";
import eyeOffIcon from "../../assets/img/icons/cards/eye-off-icon.svg";

import "./signUp.scss";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email().required("Required"),
  phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  password: Yup.string().min(8).max(32).required("Required"),
  confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

const SignUpPage: React.FC = () => {
  const { setRole } = useRoleContext();

  const history = useHistory();
  const client = useApolloClient();

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  React.useEffect(() => {
    const initClient = () => {
      gapi.auth2.init({
        clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const onSuccess = async (res: any) => {
    const signUpResponse = await client.query({
      query: GOOGLE_SIGN_UP,
      variables: { token: res.tokenId },
      fetchPolicy: "no-cache",
    });

    const jwt = document.cookie.match(/jwt=[^\s]+/);
    const jwtValue = jwt && jwt[0];

    if (signUpResponse.data?.googleSignup.user.role === "RECIPIENT" && jwtValue) {
      setRole(signUpResponse.data?.googleSignup.user.role);

      if (signUpResponse.data?.googleSignup.user.role === "RECIPIENT") {
        history.push("/dashboard");
      }
    }
  };

  const onFailure = (err: any) => {
    console.error("failed:", err);
    errorMsg("Something went wrong");
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg="5" md="7" style={{ padding: 0, display: 'flex', alignItems: 'center' }}>
          <Card className="border-0 mb-0" style={{ width: '100%'}}>
            <CardBody className="px-lg-5 py-lg-5 red-card">
              <p className="red-card-header">Skip the log in</p>
              <p className="red-card-text">No account required</p>
              <p className="red-card-subtext">Just click the link in your email to access your digital itinerary.</p>
            </CardBody> 
          </Card>
        </Col>
        <Col lg="5" md="7" style={{ padding: 0}}>
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 py-lg-5">
              <p className="signUp-text">Want an account?</p>
              <p className="signUp-description">Create an account with the email used to purchase
              your event ticket. (Accounts cannot be created with other emails)</p>
              <div className="divider-red"></div>
              <Formik
                initialValues={{ email: "", phoneNumber: "", password: "", confirmPassword: "" }}
                onSubmit={async (userInfo) => {
                  const { confirmPassword, ...rest } = userInfo;

                  const signUpResponse = await client.query({
                    query: USER_SIGN_UP,
                    variables: { registerInput: rest },
                    fetchPolicy: "no-cache",
                  });

                  const jwt = document.cookie.match(/jwt=[^\s]+/);
                  const jwtValue = jwt && jwt[0];

                  if (
                    (signUpResponse.data?.userRegister.user.role === "RECIPIENT" ||
                      signUpResponse.data?.userRegister.user.role === "BROKER") &&
                    jwtValue
                  ) {
                    setRole(signUpResponse.data?.userRegister.user.role);

                    if (
                      signUpResponse.data?.userRegister.user.role === "RECIPIENT" &&
                      !signUpResponse.data?.userRegister.user.isEmailConfirmed
                    ) {
                      history.push("/email-not-confirmed");
                    }

                    if (
                      signUpResponse.data?.userRegister.user.role === "RECIPIENT" &&
                      signUpResponse.data?.userRegister.user.isEmailConfirmed
                    ) {
                      history.push("/dashboard");
                    }

                    if (signUpResponse.data?.userRegister.user.role === "BROKER") {
                      history.push("/broker-dashboard");
                    }
                  }
                }}
                validationSchema={VALIDATION_SCHEMA}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <Form role="form" onSubmit={handleSubmit}>
                      <GoogleLogin
                        className="google-btn"
                        clientId={clientId}
                        buttonText="Sign Up with Google"
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        cookiePolicy={"single_host_origin"}
                      />
                      <FormGroup>
                        <FormText className="input-name" color="black">Email</FormText>
                        <Input
                          placeholder="Email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="email"
                          type="text"
                          value={values.email}
                          invalid={!!errors.email && touched.email}
                        />
                        {errors.email && touched.email &&
                          <FormFeedback>Field is empty or wrong email format</FormFeedback>
                        }
                      </FormGroup>
                      <FormGroup>
                        <FormText className="input-name" color="black">Phone Number</FormText>
                        <Input
                          type="text"
                          value={values.phoneNumber}
                          name="phoneNumber"
                          placeholder="Phone Number"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          invalid={!!errors.phoneNumber && touched.phoneNumber}
                        />
                        {errors.phoneNumber && touched.phoneNumber &&
                          <FormFeedback>Phone number should contain only numbers and have at least 6 symbols</FormFeedback>
                        }
                      </FormGroup>
                      <FormGroup>
                        <FormText className="input-name" color="black">Password</FormText>
                        <InputGroup style={{ position: 'relative'}}>
                          <Input
                            placeholder="Password"
                            type={isPasswordVisible ? 'text' : 'password'}
                            name="password"
                            id="password"
                            value={values.password}
                            invalid={!!errors.password && touched.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <InputGroupText onClick={togglePasswordVisibility} 
                            style={{
                              position: 'absolute',
                              top: '50%',
                              right: '10px',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                              border: 'none',
                              backgroundColor: 'transparent',
                              zIndex: 99
                            }}
                          >
                            {isPasswordVisible 
                              ? <img width="18" height="18" src={eyeIcon} alt="eye-icon"/> 
                              : <img width="18" height="18" src={eyeOffIcon} alt="eye-off-icon"/>
                            }
                          </InputGroupText>
                        </InputGroup>
                        {errors.password && touched.password && (
                          <span 
                            style={{    
                              display: 'block',
                              width: '100%',
                              marginTop: '0.25rem',
                              fontSize: '80%',
                              color: '#fb6340'
                            }}>
                            Password should contain min 8 and max 32 symbols
                          </span>
                          )}
                      </FormGroup>
                      <FormGroup>
                        <FormText className="input-name" color="black">Confirm Password</FormText>
                        <InputGroup style={{ position: 'relative'}}>
                          <Input
                            placeholder="Confirm Password"
                            type={isConfirmPasswordVisible ? 'text' : 'password'}
                            name="confirmPassword"
                            id="password"
                            value={values.confirmPassword}
                            invalid={!!errors.confirmPassword && touched.confirmPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <InputGroupText onClick={toggleConfirmPasswordVisibility} 
                            style={{
                              position: 'absolute',
                              top: '50%',
                              right: '10px',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                              border: 'none',
                              backgroundColor: 'transparent',
                              zIndex: 99
                            }}
                          >
                            {isPasswordVisible 
                              ? <img width="18" height="18" src={eyeIcon} alt="eye-icon"/> 
                              : <img width="18" height="18" src={eyeOffIcon} alt="eye-off-icon"/>
                            }
                          </InputGroupText>
                        </InputGroup>
                        {errors.confirmPassword && touched.confirmPassword && (
                          <span 
                            style={{    
                              display: 'block',
                              width: '100%',
                              marginTop: '0.25rem',
                              fontSize: '80%',
                              color: '#fb6340'
                            }}>
                             Password and confirm password should be the same
                          </span>
                          )}
                      </FormGroup>
                      <div className="text-center d-flex flex-column">
                        <button
                          className="my-2 bg-signUp-btn"
                          type="submit"
                          disabled={!dirty || isSubmitting}
                        >
                          Sign Up
                        </button>
                        <Row className="d-flex justify-content-center">
                          <p className="link-paragraph">
                            Already have an account?
                            <Link to="/login">
                              <small>Log in</small>
                            </Link>
                          </p>
                        </Row>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUpPage;

import { useState } from "react";
import ShowMoreText from 'react-show-more-text';
import { CardSubtitleGoogleLink } from "../CardSubtitleGoogleLink/CardSubtitleGoogleLink";
import editIcon from '../../../assets/img/icons/cards/edit-icon.svg';

import './cardDetails.scss';

interface CardDetailsProps {
  children?: React.ReactNode;
  cardIcon?: string;
  cardTitle?: string;
  titleStyle?: React.CSSProperties;
  cardSubtitle?: string | Record<string, any>;
  cardDescription?: string | React.ReactNode;
  isToggleExpanded?: boolean;
  isEditable?: boolean;
  cardSubtitleGoogleLink?: Record<string, any>;
}

const CardDetails = ({ 
  children, 
  cardIcon, 
  cardTitle, 
  titleStyle, 
  cardSubtitle, 
  cardSubtitleGoogleLink, 
  cardDescription, 
  isEditable = false, 
  isToggleExpanded = true 
}: CardDetailsProps ) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="card-details">
      <div className="card-details-header">
        {cardIcon && (
          <img 
            loading="lazy" 
            src={cardIcon} 
            alt="card thumbnail" 
            className="card-details-icon" 
          />
        )}
        {(cardTitle || cardSubtitle) && (
          <div className="card-details-content">
            {cardTitle && (
              <p className="card-details-title" style={titleStyle}>
                {cardTitle}
              </p>
            )}
            {cardSubtitle && (
              <p className="card-details-subtitle">
                {cardSubtitle}
              </p>
            )}
            {cardSubtitleGoogleLink && (
              <p className="card-details-subtitle">
                <CardSubtitleGoogleLink 
                  addressName={cardSubtitleGoogleLink.name || cardSubtitleGoogleLink.address} 
                  link={cardSubtitleGoogleLink.link} 
                  time={cardSubtitleGoogleLink.time} 
                />
              </p>
            )}
          </div>
        )}
        {children && !isEditable && isToggleExpanded && (
          <button 
            className="content-toggle-button" 
            onClick={toggleExpand}
          >
            {isExpanded ? 'Hide' : 'Show'}
          </button>
        )}
        {children && isEditable && (
          <div className="content-toggle-button-flex-container">
            {!isExpanded && <img src={editIcon} alt="edit-icon" />}
            <button 
              className="content-toggle-button" 
              onClick={toggleExpand}
            >
              {isExpanded ? 'Cancel' : 'Edit'}
            </button>
          </div>
        )}
      </div>

      <div className="card-details-content">
        {cardDescription && (
          <div className="card-details-description">
            <ShowMoreText
              lines={3}
              more="Read more"
              less="Read less"
              className="text-break"
              anchorClass="description-toggle-button"
              expanded={false}
            >
              {cardDescription}
            </ShowMoreText>
          </div>
        )}

        {(isExpanded || !isToggleExpanded) && children && <>{children}</>}
      </div>
    </div>
  );
};

export default CardDetails;

import { useLazyQuery } from "@apollo/client";
import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { REQUEST_CONFIRM_EMAIL_LINK } from "../../queries/auth";
import { getUserEmail } from "../../utils/services";
import { successMsg } from "../../cache/vars";
import { Link } from "react-router-dom";

import "./emailNotConfirmed.scss";

const NotConfirmed: React.FC = ({}: any) => {
  const [requestLink, { data, loading, error }] = useLazyQuery(REQUEST_CONFIRM_EMAIL_LINK);

  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg="5" md="7">
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 py-lg-5">
              <p className="emailNotConfirmed-text">Verify Your Email</p>
              <p className="emailNotConfirmed-description">Check your email and click the link to activate your account.</p>
              <div className="divider-red"></div>
              <p className="emailNotConfirmed-description">Click the link below in case you didn't receive verification email.</p>

              <div className="text-center d-flex flex-column">
                <button
                  className="my-2 bg-emailNotConfirmed-btn"
                  type="submit"
                  onClick={() => {
                    requestLink({
                      variables: {
                        email: getUserEmail(),
                      },
                    });
                    if (data?.requestConfirmEmailLink === "ok") {
                      successMsg("Link sent. Please, check your email.");
                    }
                  }}
                >
                  Resend verification email
                </button>
              </div>

              <Row className="d-flex justify-content-center">
                <p className="link-paragraph">
                  Back to log in page?
                  <Link to="/login">
                    <small>Log in</small>
                  </Link>
                </p>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NotConfirmed;

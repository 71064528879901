import { useApolloClient } from "@apollo/client";
import { Formik } from "formik";
import { gapi } from "gapi-script";
import React, { useState } from "react";
import { GoogleLogin } from "react-google-login";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import { errorMsg } from "../../cache/vars";
import { useRoleContext } from "../../Navigator";
import { AUTH, GOOGLE_AUTH } from "../../queries/auth";
import * as Yup from "yup";

import eyeIcon from "../../assets/img/icons/cards/eye-icon.svg";
import eyeOffIcon from "../../assets/img/icons/cards/eye-off-icon.svg";

import "./login.scss";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

const LoginPage: React.FC = () => {
  const { setRole } = useRoleContext();

  const history = useHistory();
  const client = useApolloClient();

  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };


  React.useEffect(() => {
    const initClient = () => {
      gapi.auth2.init({
        clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const onSuccess = async (res: any) => {
    console.log('res', res);
    const authResponse = await client.query({
      query: GOOGLE_AUTH,
      variables: { token: res.tokenId },
      fetchPolicy: "no-cache",
    });
    console.log('authResponse', authResponse);

    const jwt = document.cookie.match(/jwt=[^\s]+/);
    const jwtValue = jwt && jwt[0];

    if (
      (authResponse.data?.googleLogin.user.role === "RECIPIENT" ||
        authResponse.data?.googleLogin.user.role === "BROKER") &&
      jwtValue
    ) {
      setRole(authResponse.data?.googleLogin.user.role);

      if (authResponse.data?.googleLogin.user.role === "RECIPIENT") {
        history.push("/dashboard");
      }

      if (authResponse.data?.googleLogin.user.role === "BROKER") {
        history.push("/broker-dashboard");
      }
    }
  };

  const onFailure = (err: any) => {
    console.error("failed:", err);
    errorMsg("Couldn't Log In");
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg="5" md="7">
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 py-lg-5">
              <p className="login-text">Log in</p>
              <p className="login-description">Log in to access your account and manage your digital itinerary.</p>
              <div className="divider-red"></div>
              <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={async (userInfo) => {
                  const authResponse = await client.query({
                    query: AUTH,
                    variables: { authInput: userInfo },
                    fetchPolicy: "no-cache",
                  });

                  const jwt = document.cookie.match(/jwt=[^\s]+/);
                  const jwtValue = jwt && jwt[0];

                  if (
                    (authResponse.data?.logIn.user.role === "RECIPIENT" ||
                      authResponse.data?.logIn.user.role === "BROKER") &&
                    jwtValue
                  ) {
                    setRole(authResponse.data?.logIn.user.role);
  
                    if (
                      authResponse.data?.logIn.user.role === "RECIPIENT" &&
                      !authResponse.data?.logIn.user.isEmailConfirmed
                    ) {
                      history.push("/email-not-confirmed");
                    }

                    if (
                      authResponse.data?.logIn.user.role === "RECIPIENT" &&
                      authResponse.data?.logIn.user.isEmailConfirmed
                    ) {
                      history.push("/dashboard");
                    }

                    if (authResponse.data?.logIn.user.role === "BROKER") {
                      history.push("/broker-dashboard");
                    }
                  }
                }}
                validationSchema={VALIDATION_SCHEMA}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <Form role="form" onSubmit={handleSubmit}>
                      <GoogleLogin
                        className="google-btn"
                        clientId={clientId}
                        buttonText="Log In with Google"
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        cookiePolicy={"single_host_origin"}
                      />
                      <FormGroup>
                        <FormText className="input-name" color="black">Email</FormText>
                        <Input
                          placeholder="Email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="email"
                          type="text"
                          value={values.email}
                          invalid={!!errors.email && touched.email}
                        />
                        {errors.email && touched.email &&
                          <FormFeedback>Field is empty or wrong email format</FormFeedback>
                        }
                      </FormGroup>
                      <FormGroup>
                        <FormText className="input-name" color="black">Password</FormText>
                        <InputGroup style={{ position: 'relative'}}>
                          <Input
                            placeholder="Password"
                            type={isPasswordVisible ? 'text' : 'password'}
                            name="password"
                            id="password"
                            value={values.password}
                            invalid={!!errors.password && touched.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <InputGroupText onClick={togglePasswordVisibility} 
                            style={{
                              position: 'absolute',
                              top: '50%',
                              right: '10px',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                              border: 'none',
                              backgroundColor: 'transparent',
                              zIndex: 99
                            }}
                          >
                            {isPasswordVisible 
                              ? <img width="18" height="18" src={eyeIcon} alt="eye-icon"/> 
                              : <img width="18" height="18" src={eyeOffIcon} alt="eye-off-icon"/>
                            }
                          </InputGroupText>
                        </InputGroup>
                        {errors.password && touched.password && (
                          <span 
                            style={{    
                              display: 'block',
                              width: '100%',
                              marginTop: '0.25rem',
                              fontSize: '80%',
                              color: '#fb6340'
                            }}>
                            Field should not be empty
                          </span>
                          )}
                      </FormGroup>
                      <div className="text-center d-flex flex-column">
                        <button
                          className="my-2 bg-login-btn"
                          type="submit"
                        >
                          Log In
                        </button>
                        <Row className="d-flex justify-content-center">
                          <p className="link-paragraph">
                            Forgot your password?
                            <Link to="/forgot-password">
                              <small>Click here</small>
                            </Link>
                          </p>
                        </Row>
                        <Row className="d-flex justify-content-center">
                          <p className="link-paragraph">
                            Create new account?
                            <Link to="/register">
                              <small>Click here</small>
                            </Link>
                          </p>
                        </Row>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;

import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Button, Form, Input } from 'reactstrap';
import moment from 'moment';
import { PhoneInput } from 'react-international-phone';
import { errorMsg, successMsg } from '../../cache/vars';
import { DECLINE_SERVICE, GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO, SAVE_STAGE_ONE_CARD } from '../../queries/vip-workflow';
import { SERVICE_STATE } from '../../utils/constants';
import StagedCardLayout from '../StagedCard/StagedCardLayout/StagedCardLayout';
import CardDetails from '../StagedCard/CardDetails/CardDetails';
import GooglePlaceAutocompleteInput from '../GooglePlaceAutocomplete/GooglePlaceAutocomplete';
import CardGoogleMap from '../StagedCard/CardGoogleMap/CardGoogleMap';
import CardGuaranteed from '../StagedCard/CardGuaranteed/CardGuaranteed';
import CardAction from '../StagedCard/CardAction/CardAction';
import CardDeclineBtn from '../StagedCard/CardDeclineBtn/CardDeclineBtn';
import FlightInfoPopover from '../Popovers/FlightInfoPopover';
import { generateMailtoLink, truncateString, formatPhoneNumber } from '../../utils/services';
import reservationIcon from '../../assets/img/icons/cards/reservation-icon.svg';
import ticketIcon from "../../assets/img/icons/common/ticket-icon.svg";
import flagIcon from '../../assets/img/icons/cards/flag-icon.svg';
import mapIcon from '../../assets/img/icons/cards/map-icon.svg';
import airportIcon from '../../assets/img/icons/cards/airport-icon.svg';
import jumpIcon from '../../assets/img/icons/cards/jump-icon.svg';
import planeIcon from '../../assets/img/icons/cards/plane-icon.svg';
import planeUpIcon from '../../assets/img/icons/cards/plane-up-icon.svg';
import helpIcon from '../../assets/img/icons/cards/help-icon.svg';
import carIcon from '../../assets/img/icons/cards/car-icon.svg';
import partyIcon from '../../assets/img/icons/cards/party-icon.svg';
import GetHelpModal from '../Modal/GetHelpModal';
import { TicketsPreviewModal } from '../Modal/TicketsPreviewModal';
import { TicketActions } from '../Modal/TicketActions';
import { AirlinesDropdown } from '../Inputs/AirlinesDropdown';

import './airportTransferArrivalGroupCardNew.scss';


type AirportTransferArrivalGroupCardNewProps = { 
  cart: any;
  datetime: any | null;
  time: any | null;
  isMissingInfo: boolean;
  isPublished: boolean;
  isServiceDeclined: boolean;
  serviceDeclined: string;
  packageDetailsId: number;
  decryptedInvoiceId: any;
  setMissingInfoCards: any;
  cardDescription: string;
  cardGroup: string;
  setPackageOrderWithDetails: (data: any) => void;
  cardsWithTickets: Record<string, any>;
  ticketsForCard: Record<string, any>;
};

const AirportTransferArrivalGroupCardNew = ({
  cart,
  datetime,
  time,
  isMissingInfo,
  isPublished,
  isServiceDeclined,
  serviceDeclined,
  packageDetailsId,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
  cardDescription,
  cardGroup,
  cardsWithTickets,
  ticketsForCard,
}: AirportTransferArrivalGroupCardNewProps) => {
  const client = useApolloClient();
  
  const [file, setFile] = useState<string | undefined>("");
  const [ticket, setTicket] = useState<any | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string | undefined>("");
  const [emailLink, setEmailLink] = useState("");
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  
  const [cardState, setCardState] = useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: {},
  });

  const [isFieldsValid, setIsFieldsValid] = useState({
    contactName: true,
    contactNumber: true,
    arrivalTime: true,
    airportName: true,
    arrivalFlightAirline: true,
    flightNumber: true,
    // departureFlightDate: true,
  });
  
  const handleDeclineClick = async () => {
    if (window.confirm("Are you sure you want to decline this service?")) {
      const { data } = await client.mutate({
        mutation: DECLINE_SERVICE,
        variables: { packageDetailsId: packageDetailsId },
      });

      if (data?.declineService) {
        setPackageOrderWithDetails((prev: any) => {
          return {
            ...prev,
            orderDetails: prev.orderDetails.map((item: any) => {
              return item.packageDetailsId === packageDetailsId
                ? {
                    ...item,
                    cartDetails: {
                      ...item.cartDetails,
                      cartId: data?.declineService,
                      isServiceDeclined: SERVICE_STATE.DECLINED,
                    },
                  }
                : item;
            }),
          };
        });
        successMsg("Service Declined");
      }
    }
  };

  useEffect(() => {
    const requiredFields = [
      "contactName",
      "contactNumber",
      "arrivalTime",
      "airportName",
      "arrivalFlightAirline",
      "flightNumber",
      // "departureFlightDate",
    ];

    const isRequiredFieldsEmpty = requiredFields.some((el) => {
      return !cardState.cartData[el];
    });

    setIsSubmitBtnDisabled(isRequiredFieldsEmpty);
  }, [cardState.cartData]);
  
  useEffect(() => {
    generateMailtoLink(client, window.location.pathname, setEmailLink);
  }, [client]);
  
  const cartJsonData = JSON.parse(cart.cartDetails.cartJsonData);
  
  const tickets = ticketsForCard?.filter((ticket: any) => ticket.deliveryStatus === "UPLOADED" || ticket.eTicketLink);
  
  return (
    <StagedCardLayout
      cardIcon={airportIcon} 
      cardTitle={cart?.cartDetails?.cardTitle || "Airport Transfer Arrival"}
      datetime={datetime}
      time={time}
      isPublished={isPublished} 
      isServiceDeclined={isServiceDeclined}
      serviceDeclined={serviceDeclined}
      isMissingInfo={isMissingInfo}
      cardGroup={cardGroup}
    >
      <>
        <GetHelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tag={cardGroup.replaceAll(' ', '')} />

        {isPublished &&
          <>
            <CardDetails
              cardIcon={carIcon}
              cardTitle="Transport Details"
              cardSubtitle={cartJsonData?.vendorName}
            >
              <>
                <div className="airport-transfer-card-content">
                  {cartJsonData?.vendorPhone &&
                    <>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Vendor Phone</p>
                      </div>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                          <a href={`tel:${cartJsonData?.vendorPhone}`}>{formatPhoneNumber(cartJsonData?.vendorPhone)}</a>
                        </p>
                      </div>
                    </>
                  }
                </div>
                <div className="airport-transfer-card-content">
                  {cartJsonData?.contactName &&
                    <>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Reserved for</p>
                      </div>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.contactName}</p>
                      </div>
                    </>
                  }
                </div>
                <div className="airport-transfer-card-content">
                  {cartJsonData?.contactNumber &&
                    <>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Contact Phone</p>
                      </div>
                      <div className="card-content-column">
                        <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                          <a href={`tel:${cartJsonData?.contactNumber}`}>{formatPhoneNumber(cartJsonData?.contactNumber)}</a>
                        </p>
                      </div>
                    </>
                  }
                </div>
              </>
            </CardDetails>
            
            <div className="divider"></div>
            
            <CardDetails
              cardIcon={planeUpIcon}
              cardTitle="Pick Up At"
              cardSubtitleGoogleLink={{
                name: cartJsonData?.airportName === cartJsonData?.airportPlaceName ? truncateString(cartJsonData?.airportName, 30) : cartJsonData?.airportName, 
                address: !cartJsonData?.airportName ? truncateString(cartJsonData?.airportPlaceName, 30) : cartJsonData?.airportPlaceName, 
                link: cartJsonData?.airportPlaceLink, 
                time: cartJsonData?.date || ''
              }}
            >
              <div className="airport-transfer-card-content">
                {cartJsonData?.confirmation &&
                  <>
                    <div className="card-content-column">
                      <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Confirmation</p>
                    </div>
                    <div className="card-content-column">
                      <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.confirmation}</p>
                    </div>
                  </>
                }
              </div>
              <div className="airport-transfer-card-content">
                {cartJsonData?.arrivalTime &&
                  <>
                    <div className="card-content-column">
                      <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Airline</p>
                    </div>
                    <div className="card-content-column">
                      <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.arrivalFlightAirline}</p>
                    </div>
                  </>
                }
              </div>
              <div className="airport-transfer-card-content">
                {cartJsonData?.arrivalTime &&
                  <>
                    <div className="card-content-column">
                      <p style={{ fontSize: '14px', fontWeight: '500', fontFamily: "'Inter', sans-serif" }}>Flight Num. {cartJsonData?.flightNumber} Arriving</p>
                    </div>
                    <div className="card-content-column">
                      <div>
                        <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif", display: 'inline'}}>{moment(cartJsonData?.arrivalTime).format("h:mm a")} Flight Info</p>
                        <span style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                          <FlightInfoPopover 
                            parsedCardDetails={cartJsonData} 
                            config={{name: 'flightInfo'}} 
                            cartInfoId={packageDetailsId} 
                            cardGroup={cardGroup}
                          />
                        </span>
                      </div>
                    </div>
                  </>
                }
              </div>
              <CardGoogleMap address={cartJsonData?.dropOffPlaceName || cartJsonData?.airportPlaceName} className="pl-4" />
            </CardDetails>
            
            <div className="divider"></div>

            <CardDetails
              cardIcon={mapIcon}
              cardTitle="Transporting To"
              cardSubtitleGoogleLink={{
                name: cartJsonData.dropOffNamе === cartJsonData.dropOffPlaceName ? truncateString(cartJsonData.dropOffNamе, 30) : cartJsonData.dropOffNamе,
                address:
                !cartJsonData?.dropOffName
                  ? cartJsonData?.dropOffPlaceName
                    ? truncateString(cartJsonData?.dropOffPlaceName, 30)
                    : cartJsonData?.hotelName
                    ? truncateString(cartJsonData?.hotelName, 30)
                    : cartJsonData?.hotelPlaceName
                    ? truncateString(cartJsonData?.hotelPlaceName, 30)
                    : ''
                  : cartJsonData?.dropOffName || truncateString(cartJsonData?.dropOffPlaceName, 30),              
                link: cartJsonData.dropOffPlaceName ? cartJsonData.dropOffPlaceLink : cartJsonData?.hotelPlaceLink ? cartJsonData?.hotelPlaceLink : '', 
                time: ''
              }}
            >
              <CardGoogleMap address={cartJsonData.dropOffPlaceName ? cartJsonData.dropOffPlaceName : cartJsonData?.hotelPlaceName} className="pl-4" />
            </CardDetails>
            
            {!!tickets?.length &&
              <>
                <div className="divider"></div>
                <CardDetails
                  cardIcon={ticketIcon}
                  cardTitle="Access Tickets now"
                  children={
                    <div className="mt-4">
                      {ticketsForCard && ticketsForCard.length ? (
                        ticketsForCard.map((ticket: any, index: number) => (
                          <TicketActions
                            ticket={ticket}
                            setFile={setFile}
                            setTicket={setTicket}
                            setIsModalOpened={setIsModalOpened}
                          />
                        ))
                      ) : (
                        <p>No tickets available</p>
                      )}
                    </div>
                  }
                />
              </>
            }
            
            {cartJsonData?.additionalRequests &&
              <>
                <div className="divider"></div>
                
                <CardDetails
                  cardIcon={flagIcon}
                  cardTitle="Additional Requests"
                  cardSubtitle={cartJsonData?.additionalRequests}
                />
              </>
            }
            
            {cartJsonData?.celebration &&
              <>
                <div className="divider"></div>
                
                <CardDetails
                  cardIcon={partyIcon}
                  cardTitle="Celebration"
                  cardSubtitle={cartJsonData?.celebration}
                />
              </>
            }
            
            <div className="divider"></div>
            
            {cartJsonData?.notes &&
              <>
                <CardDetails
                  cardIcon={flagIcon}
                  cardTitle="Additional Notes"
                  cardDescription={cartJsonData.notes}
                />

                <div className="divider"></div>
              </>
            }
            
            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        }

        {!isPublished && isMissingInfo && (
          <>
            <CardDetails
              isEditable={true}
              cardIcon={planeIcon}
              cardTitle="Arrival Details"
              cardSubtitle="Please provide your info"
            >
              <>
                <p className="mt-4 mr-4 mb-0 ml-2" style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>
                  Provide us with your arrival flight information. Transfers are reserved in a vehicle accommodating the size of your party associated to the package. Special vehicle types and additional stops will require an additional charge.
                </p>
                
                <div className="divider"></div>
                
                <div className="mb-4">
                  <p style={{ fontSize: '20px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>Contact Details</p>
                  <p className="required-note" style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>* These fields are required.</p>
                </div>
                
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    
                    const requiredFields = [
                      "contactName",
                      "contactNumber",
                      "arrivalTime",
                      "airportName",
                      "arrivalFlightAirline",
                      "flightNumber",
                      // "departureFlightDate",
                    ];
                
                    const isRequiredFieldsEmpty = requiredFields.some((el) => !cardState.cartData[el]);

                    if (isRequiredFieldsEmpty) {
                      setIsFieldsValid({
                        contactName: !!cardState.cartData.contactName,
                        contactNumber: !!cardState.cartData.contactNumber,
                        arrivalTime: !!cardState.cartData.arrivalTime,
                        airportName: !!cardState.cartData.airportName,
                        arrivalFlightAirline: !!cardState.cartData.arrivalFlightAirline,
                        flightNumber: !!cardState.cartData.flightNumber,
                        // departureFlightDate: !!cardState.cartData.departureFlightDate,
                      });

                      errorMsg("Required Fields Should Not Be Empty");

                      return;
                    }

                    const { data } = await client.mutate({
                      mutation: SAVE_STAGE_ONE_CARD,
                      variables: { editCartDetailsInput: cardState },
                    });

                    const filtersData = await client.query({
                      query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
                      variables: { invoiceId: decryptedInvoiceId },
                      fetchPolicy: "no-cache",
                    });

                    if (data) {
                      setPackageOrderWithDetails((prev: any) => {
                        return {
                          ...prev,
                          orderDetails: [
                            ...prev.orderDetails.map((cart: any) => {
                              return cart.packageDetailsId === packageDetailsId
                                ? {
                                    ...cart,
                                    cartDetails: {
                                      ...cart.cartDetails,
                                      cardTitle: data?.saveStageOneCard.cardTitle,
                                      cartId: data?.saveStageOneCard.cartId,
                                      cartJsonData: data?.saveStageOneCard.cartJsonData,
                                      isPublished: data?.saveStageOneCard.isPublished,
                                      packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                                    },
                                  }
                                : cart;
                            }),
                          ],
                        };
                      });

                      setMissingInfoCards(
                        filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor,
                      );

                      successMsg("Saved");
                    }
                  }}>

                    <div className="inputs-container">
                    <div className="contacts-container">
                      <div className="input-container">
                        <label className={!isFieldsValid.contactName ? "label-invalid" : ""}>
                          Contact Name
                        </label>
                        <div className="input-box">
                          <Input
                            className={!isFieldsValid.contactName ? "input-invalid" : ""}
                            type="text"
                            placeholder="Contact Name"
                            name="contactName"
                            invalid={!isFieldsValid.contactName}
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    [e.target.name]: e.target.value,
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.contactName || ""}
                          />
                          <span></span>
                        </div>
                      </div>
                      <div className="input-container">
                        <label className={!isFieldsValid.contactNumber ? "label-invalid" : ""}>
                          Contact Number
                        </label>
                        <div className="input-box">
                          <PhoneInput
                            className={!isFieldsValid.contactNumber ? "input-invalid" : ""}
                            style={{ marginTop: "8px" }}    
                            dialCodePreviewStyleProps={{style: { zIndex: 2, backgroundColor: 'red' }}}
                            countrySelectorStyleProps={{ buttonStyle: { height: "46px", marginTop: 0 }}}
                            inputStyle={{ borderLeft: 0, height: "46px", marginTop: 0 }}
                            defaultCountry="us"
                            value={cardState?.cartData?.contactNumber || ''}
                            onChange={(value: any) => {
                              if (setCardState) {
                                setCardState((prev) => ({
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    contactNumber: value,
                                  },
                                }));
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    
                    <div className="divider"></div>
                    
                    <div style={{ marginBottom: '20px' }}>
                      <p style={{ fontSize: '20px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>Arrival Flight Details</p>
                      <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }} className="required-note">* These fields are required.</p>
                    </div>
                    
                    {/* <div className="input-container">
                      <label className={!isFieldsValid.departureFlightDate ? "label-invalid" : ""}>
                        Departure Date
                      </label>
                      <div className="input-box">
                        <Input
                          style={{ color: '#71717A'}}
                          className={`custom-datetime-input ${!isFieldsValid.departureFlightDate ? "input-invalid" : ""}`}
                          type="datetime-local"
                          min="1900-01-01T00:00"
                          max="2100-12-31T23:59"
                          placeholder="Departure Flight Date"
                          name="departureFlightDate"
                          invalid={!isFieldsValid.departureFlightDate}
                          onChange={(e) => {
                            setCardState((prev: any) => {
                              return {
                                ...prev,
                                cartData: {
                                  ...prev.cartData,
                                  [e.target.name]: e.target.value,
                                },
                              };
                            });
                          }}
                          value={cardState.cartData.departureFlightDate || ""}
                        />
                        <span></span>
                      </div>
                    </div> */}

                    <div className="input-container">
                      <label className={!isFieldsValid.arrivalTime ? "label-invalid" : ""}>
                        Arrival Date
                      </label>
                      <div className="input-box">
                        <Input
                          style={{ color: '#71717A'}}
                          className={`custom-datetime-input ${!isFieldsValid.arrivalTime ? "input-invalid" : ""}`}
                          type="datetime-local"
                          placeholder="Arrival Flight Date"
                          name="arrivalTime"
                          invalid={!isFieldsValid.arrivalTime}
                          onChange={(e) => {
                            setCardState((prev: any) => {
                              return {
                                ...prev,
                                cartData: {
                                  ...prev.cartData,
                                  [e.target.name]: e.target.value,
                                },
                              };
                            });
                          }}
                          value={cardState.cartData.arrivalTime || ""}
                        />
                        <span></span>
                      </div>
                    </div>
                    <div className="input-container">
                      <label className={!isFieldsValid.airportName ? "label-invalid" : ""}>
                        Arrival Flight Airport
                      </label>
                      <div className="input-box">
                        <GooglePlaceAutocompleteInput
                          cardState={cardState}
                          setCardState={setCardState}
                          placeLabel={"airportName"}
                          placeName={"airportPlaceName"}
                          placeValueName={"airportPlaceValue"}
                          placeLinkName={"airportPlaceLink"}
                        />
                        <span></span>
                      </div>
                    </div>{" "}

                    <div className="input-container">
                      <label
                        className={!isFieldsValid.arrivalFlightAirline ? "label-invalid" : ""}
                      >
                        Arrival Flight Airline
                      </label>
  
                      <div className="input-box">
                        <AirlinesDropdown
                          className={!isFieldsValid.arrivalFlightAirline ? "input-invalid" : ""}
                          placeholder="Arrival Flight Airline"
                          name="arrivalFlightAirline"
                          value={cardState?.cartData?.arrivalFlightAirline || ''}
                          onSelect={(value: string) => {
                            setCardState((prev) => ({
                              ...prev,
                              cartData: { ...prev.cartData, arrivalFlightAirline: value },
                            }));
                          }}
                        />
                        {/* <Input
                          className={!isFieldsValid.arrivalFlightAirline ? "input-invalid" : "ml-2"}
                          type="text"
                          placeholder="Arrival Flight Airline"
                          name="arrivalFlightAirline"
                          invalid={!isFieldsValid.arrivalFlightAirline}
                          onChange={(e) => handleAirlineChange(e.target.value)}
                          value={cardState.cartData.arrivalFlightAirline || ''}
                        /> */}
                        <span></span>
                      </div>
                    </div>{" "}
                    <div className="input-container">
                      <label className={!isFieldsValid.flightNumber ? "label-invalid" : ""}>
                        Arrival Flight Number
                      </label>
                      <div className="input-box">
                        <Input
                          className={!isFieldsValid.flightNumber ? "input-invalid" : ""}
                          type="text"
                          placeholder="Arrival Flight Number"
                          name="flightNumber"
                          invalid={!isFieldsValid.flightNumber}
                          onChange={(e) => {
                            setCardState((prev: any) => {
                              return {
                                ...prev,
                                cartData: {
                                  ...prev.cartData,
                                  [e.target.name]: e.target.value,
                                },
                              };
                            });
                          }}
                          value={cardState.cartData.flightNumber || ""}
                        />
                        <span></span>
                      </div>
                    </div>{" "}
                    
                    <div className="divider"></div>
                    
                    <div>
                      <p style={{ fontSize: '20px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>Drop-off Address</p>
                      <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }} className="optional-note">Optional.</p>
                      <p style={{ fontSize: '12px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }} className="optional-note">We’ll arrange transportation to your hotel - no address needed. If you'd prefer a different drop-off location within 30 minutes of the airport, enter it here.</p>
                    </div>
                    <div className="input-container">
                      {/* <label>Drop-off Address (if other than package hotel)</label> */}
                      <div className="input-box">
                        <GooglePlaceAutocompleteInput
                          cardState={cardState}
                          setCardState={setCardState}
                          placeLabel={"dropOffName"}
                          placeName={"dropOffPlaceName"}
                          placeValueName={"dropOffPlaceValue"}
                          placeLinkName={"dropOffPlaceLink"}
                        />
                        <span></span>
                      </div>
                    </div>{" "}
                  </div>
                    
                  <div className="divider"></div>
                  
                  <div className="inputs-container">
                      <p style={{ fontSize: '20px', fontWeight: '400', fontFamily: "'Inter', sans-serif", margin: 0 }}>
                        Additional Requests
                      </p>
                      <div className="input-container">
                        <div className="input-box">
                          <Input
                            type="textarea"
                            placeholder="Additional Requests"
                            name="additionalRequests"
                            onChange={(e) =>
                              setCardState((prev) => ({
                                ...prev,
                                cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                              }))
                            }
                          />
                          <span></span>
                        </div>
                      </div>
                      <div className="input-container">
                        <div className="input-box">
                          <Input
                            type="textarea"
                            placeholder="Are you celebrating a special occasion?"
                            name="celebration"
                            onChange={(e) =>
                              setCardState((prev) => ({
                                ...prev,
                                cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                              }))
                            }
                          />
                          <span></span>
                        </div>
                      </div>
                  </div>
                  
                  <div className="actions-container">
                    {!isServiceDeclined && <CardDeclineBtn handleClick={handleDeclineClick} />}
                    <Button disabled={isSubmitBtnDisabled} className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              </>
            </CardDetails>

            <div className="divider"></div> 

            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        )}

        {!isPublished && !isMissingInfo &&
          <>
            <CardDetails
              isToggleExpanded={false}
              cardIcon={reservationIcon}
              cardTitle="Departure Reservation Details"
            >
              <>
                <CardGuaranteed 
                  text={isServiceDeclined && serviceDeclined !== 'UNDECLINED' 
                    ? "You’ve declined this service." 
                    : "Details will be provided as the event approaches."} 
                  subtext={isServiceDeclined 
                    ? "If you need further assistance please use the Get Help button." 
                    : ""
                  } 
                />
                {!isServiceDeclined &&
                  <>
                    <div className="divider"></div>

                    <div className="actions-container">
                      <CardDeclineBtn handleClick={handleDeclineClick} />
                    </div>
                  </>
                }
              </>
            </CardDetails>
            
            {/* <div className="divider"></div>
            
            <CardAction icon={jumpIcon} text="Jump to departure details" handleClick={() => alert('Jump to departure details')} /> */}
            
            <div className="divider"></div> 

            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        }
        
        <TicketsPreviewModal
          isModalOpened={isModalOpened}
          setIsModalOpened={setIsModalOpened}
          file={file}
          ticket={ticket}
        />
      </>
    </StagedCardLayout>
  );
};

export default AirportTransferArrivalGroupCardNew;
import React from "react";

import logoImgMobile from '../../assets/img/images/elevate-logo-mobile.png';

import "./authLayout.scss";

export const AuthLayout = React.memo(({ children }: any) => {
  return (
    <div className="auth-layout">
        <div className="logo-container">
            <img width="230" height="44" className="logo-img-mobile" src={logoImgMobile} alt="elevate-logo-mobile" />
        </div>

        <div className="text-container d-none d-sm-block">
            <p className="text">Access Your Digital</p>
            <p className="text">Itinerary Instantly</p>
        </div>

        <div>
            {children}
        </div>
    </div>
  );
});

import { useEffect, useState } from 'react';
import { Modal, Spinner } from 'reactstrap';
import { Document, Page, pdfjs } from 'react-pdf';
import downloadBlackIcon from "../../assets/img/icons/cards/download-black-icon.svg";
import ticketsIcon from "../../assets/img/icons/cards/tickets-icon.svg";
import "./ticketsPreviewModal.scss";
import { base64ToBlob } from "../../utils/services";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const TicketsPreviewModal = ({
  isModalOpened,
  setIsModalOpened,
  ticket,
  file,
}: any) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 575);
  const [numPages, setNumPages] = useState<null | number>(null);
  const [loading, setLoading] = useState(true);

  const isPdf = file && file.startsWith('data:application/pdf');

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setLoading(false);
  };

  const renderFile = () => {
    if (isPdf) {
      return (
        <div style={{ maxWidth: '500px', maxHeight: '70vh', overflowY: 'auto', margin: '0 auto' }}>
          {loading && <Spinner />}
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(error) => console.error('Error loading PDF', error)}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        </div>
      );
    } else {
      return (
        <div style={{ textAlign: 'center' }}>
          <img
            src={file}
            style={{ maxWidth: '100%', maxHeight: '100%', display: 'inline-block' }}
            alt="Ticket Preview"
          />
        </div>
      );
    }
  };
  
  const handleDownloadTicket = () => {
    if (file) {
      try {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any)?.MSStream;
        if (isIOS) {
          try {
            const matches = file.match(/^data:(.*);base64,/);
            const mimeType = matches ? matches[1] : "application/octet-stream";
            const base64Content = file.split(",")[1];
            const blob = base64ToBlob(base64Content, mimeType);
            const blobUrl = URL.createObjectURL(blob);
            const newWindow = window.open("", "_blank");
            if (newWindow) {
              newWindow.location.href = blobUrl;
            }
            setTimeout(() => URL.revokeObjectURL(blobUrl), 1000);
          } catch (error) {
            console.error("Error downloading the file:", error);
          }
        } else {
          const link = document.createElement("a");
          link.href = file;
          link.download = ticket.key;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        }
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
    } else {
      console.warn("No file available to download.");
    }
  };
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 575);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Modal
      isOpen={isModalOpened}
      toggle={() => setIsModalOpened(false)}
      contentClassName="border-radius bg-white"
      className={isMobileView ? 'modal-dialog-centered modal-secondary mt-6' : 'modal-dialog-centered modal-secondary'}
      size="lg"
      fade={false}
    >
      <div className="modal-header">
        <div className="d-flex align-items-center">
          <img className="mr-2" src={ticketsIcon} alt="tickets-icon" />
          <span className="tickets-details-span">Ticket Details</span>
        </div>
        <div className="d-flex align-items-center">
          <button
            type="button"
            style={{ background: 'none', border: 'none', cursor: 'pointer', marginBottom: '1px' }}
            onClick={handleDownloadTicket}
            >
              <img src={downloadBlackIcon} alt="download-icon" style={{ cursor: 'pointer' }}/>
          </button>
  
          <button
            type="button"
            style={{ background: 'none', border: 'none', fontSize: '1.5rem', cursor: 'pointer' }}
            onClick={() => setIsModalOpened(false)}
            >
              <i className="fas fa-times" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="modal-body" onClick={() => {
        setIsModalOpened(false);
      }}>
        {renderFile() || <Spinner />}
      </div>
    </Modal>
  );
};

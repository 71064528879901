import { useApolloClient } from "@apollo/client";
import { Formik } from "formik";
import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { successMsg } from "../../cache/vars";
import { RESET_PASSWORD } from "../../queries/auth";

import eyeIcon from "../../assets/img/icons/cards/eye-icon.svg";
import eyeOffIcon from "../../assets/img/icons/cards/eye-off-icon.svg";
import alertCircleIcon from "../../assets/img/icons/cards/alert-circle-icon.svg";

import "./resetPassword.scss";

const VALIDATION_SCHEMA = Yup.object().shape({
  password: Yup.string().min(8).max(32).required("Required"),
  confirmedPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

const ResetPasswordPage: React.FC = ({}: any) => {
  const client = useApolloClient();
  const history = useHistory();

  const { token } = useParams<{ token: string }>();

  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  return (
    <Container className="mt-8 pb-5">
      <Row className="justify-content-center">
        <Col lg="5" md="7">
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 py-lg-5">
              <p className="resetPassword-text">Reset Your Password</p>
              <p className="resetPassword-description">Enter a new password below to update your account.</p>
              <div className="divider-red"></div>
              <Formik
                initialValues={{ password: "", confirmedPassword: "" }}
                onSubmit={async (userInfo) => {
                  const resetPasswordResponse = await client.mutate({
                    mutation: RESET_PASSWORD,
                    variables: { password: userInfo.password, token },
                    fetchPolicy: "no-cache",
                  });

                  if (resetPasswordResponse.data?.resetPassword === "ok") {
                    history.push("/login");
                    successMsg("Password changed");
                  }
                }}
                validationSchema={VALIDATION_SCHEMA}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <Form role="form" onSubmit={handleSubmit}>
                      <FormGroup>
                        <FormText className="input-name" color="black">Password</FormText>
                        <InputGroup style={{ position: 'relative'}}>
                          <Input
                            placeholder="Password"
                            type={isPasswordVisible ? 'text' : 'password'}
                            name="password"
                            id="password"
                            value={values.password}
                            invalid={!!errors.password && touched.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <InputGroupText onClick={togglePasswordVisibility} 
                            style={{
                              position: 'absolute',
                              top: '50%',
                              right: '10px',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                              border: 'none',
                              backgroundColor: 'transparent',
                              zIndex: 99
                            }}
                          >
                            {isPasswordVisible 
                              ? <img width="18" height="18" src={eyeIcon} alt="eye-icon"/> 
                              : <img width="18" height="18" src={eyeOffIcon} alt="eye-off-icon"/>
                            }
                          </InputGroupText>
                        </InputGroup>
                        {errors.password && touched.password && (
                          <span 
                            style={{    
                              display: 'block',
                              width: '100%',
                              marginTop: '0.25rem',
                              fontSize: '80%',
                              color: '#fb6340'
                            }}>
                            Field should not be empty
                          </span>
                        )}
                      </FormGroup>

                      <div className="d-flex align-items-center mb-2">
                        <img width="18" height="18" src={alertCircleIcon} alt="alert-circle-icon" />
                        <p className="validation-description">Must be at least 8 characters long</p>
                      </div>
                      {/* <div className="d-flex align-items-center justify-content-center mb-2">
                        <img width="18" height="18" src={alertCircleIcon} alt="alert-circle-icon" />
                        <p className="validation-description">Must contain an uppercase and <br />
                        a lowercase letter (A, z)</p>
                      </div>
                      <div className="d-flex align-items-center justify-content-center mb-2">
                        <img width="18" height="18" src={alertCircleIcon} alt="alert-circle-icon" />
                        <p className="validation-description">Must contain a number</p>
                      </div>
                      <div className="d-flex align-items-center justify-content-center mb-4">
                        <img width="18" height="18" src={alertCircleIcon} alt="alert-circle-icon" />
                        <p className="validation-description">Must contain a special character !, %,@, #,</p>
                      </div> */}

                      <FormGroup>
                        <FormText className="input-name" color="black">Confirm Password</FormText>
                        <InputGroup style={{ position: 'relative'}}>
                          <Input
                            placeholder="Confirm New Password"
                            type={isPasswordVisible ? 'text' : 'password'}
                            name="confirmedPassword"
                            id="confirmedPassword"
                            value={values.confirmedPassword}
                            invalid={!!errors.confirmedPassword && touched.confirmedPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <InputGroupText onClick={togglePasswordVisibility} 
                            style={{
                              position: 'absolute',
                              top: '50%',
                              right: '10px',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                              border: 'none',
                              backgroundColor: 'transparent',
                              zIndex: 99
                            }}
                          >
                            {isPasswordVisible 
                              ? <img width="18" height="18" src={eyeIcon} alt="eye-icon"/> 
                              : <img width="18" height="18" src={eyeOffIcon} alt="eye-off-icon"/>
                            }
                          </InputGroupText>
                        </InputGroup>
                        {errors.confirmedPassword && touched.confirmedPassword && (
                          <span 
                            style={{    
                              display: 'block',
                              width: '100%',
                              marginTop: '0.25rem',
                              fontSize: '80%',
                              color: '#fb6340'
                            }}>
                            Password and confirm password should be the same
                          </span>
                          )}
                      </FormGroup>

                      <div className="text-center">
                        <button
                          className="my-2 bg-resetPassword-btn"
                          type="submit"
                          disabled={!dirty || isSubmitting}
                        >
                          Reset Password
                        </button>
                      </div>
                      <Row className="d-flex justify-content-center">
                        <p className="link-paragraph">
                          Back to log in page?
                          <Link to="/login">
                            <small>Log in</small>
                          </Link>
                        </p>
                      </Row>
                    </Form>
                  );
                }}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPasswordPage;

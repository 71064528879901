import React from "react";
import { Route, Switch, Redirect, RouteProps, useLocation, matchPath } from "react-router-dom";
import { CommonLayout } from "./Layout/CommonLayout/CommonLayout";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/Login/ForgotPassword";
import SignUp from "./pages/Login/SignUp";
import PackageInfoPage from "./pages/PackageInfo/PackageInfo";
import PackageOrdersTable from "./pages/PackageOrdersTable/PackageOrdersTable";
import { isEmailConfirmed, checkToken, getUserRole } from "./utils/services";
import ResetPassword from "./pages/Login/ResetPassword";
import ConfirmEmailPage from "./pages/Login/ConfirmEmail";
import NotConfirmed from "./pages/Login/EmailNotConfirmed";
import NotFound from "./pages/NotFound/NotFound";
import CryptoJS from 'crypto-js/aes';
import { CRYPTO_JS_SECRET } from "./utils/constants";
import { AuthLayout } from "./Layout/CommonLayout/AuthLayout";

export enum Role {
  RECIPIENT = "RECIPIENT",
  BROKER = "BROKER",
}
interface IRedirectRules {
  route: RouteProps;
  only?: Role[];
  isPublic?: boolean;
}

interface IUserRole {
  role: Role;
  setRole: (role: Role) => void;
}

const RoleContext = React.createContext<IUserRole>({
  role: getUserRole(),
  setRole: () => {},
});

export const useRoleContext = () => React.useContext(RoleContext);

export const RedirectRules: IRedirectRules[] = [
  { route: { path: "/dashboard" }, only: [Role.RECIPIENT], isPublic: false },
  { route: { path: "/broker-dashboard" }, only: [Role.BROKER], isPublic: false },
  { route: { path: "/package-info/:invoiceSecret" }, isPublic: true },
  { route: { path: "/login" }, isPublic: true },
  { route: { path: "/register" }, isPublic: true },
  { route: { path: "/forgot-password" }, isPublic: true },
  { route: { path: "/reset-password" }, isPublic: true },
  { route: { path: "/confirm-email" }, isPublic: true },
  { route: { path: "/email-not-confirmed" }, isPublic: false },
  { route: { path: "/not-found" }, isPublic: true },
];

const PrivateRoute = ({ component: Component, ...rest }: any) => (
  <Route
    {...rest}
    render={(props: any) =>
      checkToken() ? (
        <CommonLayout>
          <Component {...props} {...rest} />
        </CommonLayout>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      )
    }
  />
);

export const useRedirect = (): {
  redirect?: string;
} => {
  const { role } = useRoleContext();
  const { pathname } = useLocation();
  const rule = RedirectRules.find((rule) => matchPath(pathname, rule.route));
  const shouldRedirect = rule?.only && rule.only.indexOf(role) < 0;

  if (checkToken() && !rule?.isPublic && !isEmailConfirmed() && role === Role.RECIPIENT) {
    return { redirect: "/email-not-confirmed" };
  }

  if ((role === Role.BROKER && shouldRedirect) || role === Role.BROKER) {
    return { redirect: "/broker-dashboard" };
  }

  if (role === Role.RECIPIENT && shouldRedirect) {
    return { redirect: "/dashboard" };
  }

  if (!checkToken() && !rule?.isPublic) {
    return { redirect: "/login" };
  }

  if (checkToken() && pathname === "/") {
    if (role === Role.RECIPIENT) {
      return { redirect: "/dashboard" };
    } else {
      return { redirect: "/broker-dashboard" };
    }
  }

  return {};
};

export const RedirectHandler: React.FC = ({ children }) => {
  const { redirect } = useRedirect();
  const { pathname } = useLocation();
  if (redirect && pathname.match(/invoice\/shareLink/)) {
    return <>{children}</>;
  }

  if (redirect) {
    return (
      <>
        {children}
        <Redirect to={redirect} />
      </>
    );
  }

  return <>{children}</>;
};

export const Navigator: React.FC = () => {
  return (
    <Switch>
      <RedirectHandler>
        <PrivateRoute exact component={Dashboard} path="/dashboard" />
        <PrivateRoute exact component={PackageOrdersTable} path="/broker-dashboard" />
        <Route exact path="/invoice/shareLink" 
          render={({ location }) => {
            const invoiceIdStr = new URLSearchParams(location.search).get('invoiceId');
            const invoiceId = invoiceIdStr ? parseFloat(invoiceIdStr) : null;

            const encryptedInvoiceId = CryptoJS.encrypt(
              `${invoiceId}`,
              CRYPTO_JS_SECRET,
            ).toString();

            const encryptedInvoiceIdWithReplacedSlashes = encryptedInvoiceId.replace(
              /\//g,
              'HjsOwpeSBqdLafaFsjhHfd73sb7kdheYTsd61',
            );

            const invoiceLink: string = `/package-info/${encryptedInvoiceIdWithReplacedSlashes}`;

            return <Redirect to={invoiceLink} />
          }}>
        </Route>
        <Route
          path="/package-info/:invoiceSecret"
          render={(props: any) => (
            <CommonLayout>
              <PackageInfoPage {...props} />
            </CommonLayout>
          )}
        />
        <Route exact 
          path="/login"
          render={() => (
            <AuthLayout>
              <Login />
            </AuthLayout>
          )}
        />
        <Route 
          path="/register" 
          render={() => (
            <AuthLayout>
              <SignUp />
            </AuthLayout>
          )}
        />
        <Route 
          path="/forgot-password"
          render={() => (
            <AuthLayout>
              <ForgotPassword />
            </AuthLayout>
          )}
        />

        <Route exact path="/reset-password/:token" 
          render={() => (
            <AuthLayout>
              <ResetPassword />
            </AuthLayout>
          )}
        />

        <Route
          exact
          path="/confirm-email/:token"
          render={() => (
            <AuthLayout>
              <ConfirmEmailPage />
            </AuthLayout>
          )}
        />



        <Route
          path="/email-not-confirmed"
          render={() => (
            <AuthLayout>
              <NotConfirmed />
            </AuthLayout>
          )}
        />
        <Route
          path="/not-found"
          render={() => (
            <CommonLayout>
              <NotFound />
            </CommonLayout>
          )}
        />
      </RedirectHandler>
    </Switch>
  );
};
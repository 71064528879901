import { useApolloClient } from "@apollo/client";
import React from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { GET_BROKER_DASHBOARD_DATA, GET_PACKAGES_FOR_CLIENT } from "../../queries/vip-workflow";
import TableLayout from "./TableLayout";
import decodeToken from "jwt-decode";
import { TABLE_CONFIG, TABS, TABS_LABELS } from "../../utils/constants";
import NavbarTabs from "../../components/Tabs/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import { clientsFilter } from "../../cache/vars";
import { GET_BROKER_PROFILE } from "../../queries/auth";

const PackageOrdersTable: React.FC = () => {
  const client = useApolloClient();
  const location = useLocation();
  const history = useHistory();
  const currentSection: string = location?.pathname?.slice(1)?.toUpperCase().replace("-", "_");
  const tabQueryParamValue = new URLSearchParams(location.search).get("tab");

  const clientsFilterState = clientsFilter();

  const [ordersData, setOrdersData] = React.useState<any>([]);

  const getCurrentTabValue = TABS_LABELS[tabQueryParamValue as keyof typeof TABS_LABELS];

  const [locationKeys, setLocationKeys] = React.useState<(string | undefined)[]>([]);
  const [currentTab, setCurrentTab] = React.useState(getCurrentTabValue || TABS_LABELS.UPCOMING);
  const [isDetailsOpened, setIsDetailsOpened] = React.useState<number[]>([]);
  const [loading, setLoading] = React.useState(false);

  const [searchInvoiceId, setSearchInvoiceId] = React.useState(null);
  const [searchPoId, setSearchPoId] = React.useState(null);
  const [isAllowedLoadingInCurrentTab, setIsAllowedLoadingInCurrentTab] = React.useState(false);
  const [page, setPage] = React.useState<number>(1);
  const [filterState, setFilterState] = React.useState({});
  const [sortBy, setSortBy] = React.useState("");
  const [sortDirection, setSortDirection] = React.useState("");
  const [sortDateRangeBy, setSortDateRangeBy] = React.useState("saleDate");
  const [dateRange, setDateRange] = React.useState(null);
  const [invoiceIdInputState, setInvoiceIdInputState] = React.useState("");
  const [poInputSate, setPoInputState] = React.useState("");
  const [totalPageCount, setTotalPageCount] = React.useState(0);
  const [totalPackagesCount, setTotalPackagesCount] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState<number>(25);
  // const [searchInCurrentTab, setSearchInCurrentTab] = React.useState(false);
  // const [filter, setFilter] = React.useState({});
  // const [recipientNameSearch, setRecipientNameSearch] = React.useState(null);

  const getPackageQuery = async () => {
    setLoading(true);

    if (ordersData.length) {
      setOrdersData([]);
    }

    const profile = await client.query({
      query: GET_BROKER_PROFILE,
      fetchPolicy: "cache-first",
    });

    const initialOrdersData = await client.query({
      query: GET_BROKER_DASHBOARD_DATA,
      variables: {
        invoiceId: invoiceIdInputState ? Number.parseFloat(invoiceIdInputState) : null,
        sortDateRangeBy: sortDateRangeBy && dateRange ? sortDateRangeBy : null,
        dateRange: sortDateRangeBy && dateRange ? dateRange : null,
        brokerId: profile.data?.getProfile.brokerId,
        showOrders: currentTab || TABS_LABELS.UPCOMING,
        page,
        itemsPerPage,
        sortBy,
        sortDirection,
        filter: filterState,
      },
      fetchPolicy: "cache-first",
    });

    clientsFilter(null);
    setLoading(false);
    setOrdersData(initialOrdersData.data?.getBrokerDashboardPackages.orders);
    setTotalPageCount(initialOrdersData.data?.getBrokerDashboardPackages.totalPageCount);
    setTotalPackagesCount(initialOrdersData.data?.getBrokerDashboardPackages?.totalPackagesCount);
    setLoading(false);
  };

  React.useEffect(() => {
    getPackageQuery();

    if (!tabQueryParamValue) {
      history.push(`broker-dashboard?tab=${TABS_LABELS.UPCOMING}`);
    }
  }, []);

  React.useEffect(() => {
    getPackageQuery();
  }, [currentTab, page, sortBy, sortDirection, itemsPerPage]);

  React.useEffect(() => {
    if (clientsFilterState) {
      setCurrentTab(getCurrentTabValue);
    }
  }, [location]);

  React.useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        if (location.key) {
          setLocationKeys([location.key]);
        }
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          const tabQueryParamValueOnForwardClick = new URLSearchParams(location.search).get("tab");
          if (tabQueryParamValueOnForwardClick) {
            setPage(1);
            setSortBy("");
            setSortDirection("");
            setCurrentTab(
              TABS_LABELS[tabQueryParamValueOnForwardClick as keyof typeof TABS_LABELS],
            );
          }
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          const tabQueryParamValueOnBackClick = new URLSearchParams(location.search).get("tab");
          if (tabQueryParamValueOnBackClick) {
            setPage(1);
            setSortBy("");
            setSortDirection("");
            setCurrentTab(TABS_LABELS[tabQueryParamValueOnBackClick as keyof typeof TABS_LABELS]);
          }
        }
      }
    });
  }, [locationKeys]);

  const handleCurrentTab = (title: string) => {
    setCurrentTab(title);
    // setFilter({});
    if (invoiceIdInputState) {
      setSearchInvoiceId(null);
      setInvoiceIdInputState("");
    }

    if (poInputSate) {
      setSearchPoId(null);
      setPoInputState("");
    }

    setPage(1);
    setSortBy("");
    setSortDirection("");

    history.push(
      `broker-dashboard?tab=${
        Object.entries(TABS_LABELS)
          .filter(([key, value]) => value === title)
          .flat()[0]
      }`,
    );
  };

  const handleApplyFilters = async () => {
    setLoading(true);

    if (ordersData.length) {
      setOrdersData([]);
    }

    const profile = await client.query({
      query: GET_BROKER_PROFILE,
      fetchPolicy: "cache-first",
    });

    const initialOrdersData = await client.query({
      query: GET_BROKER_DASHBOARD_DATA,
      variables: {
        sortDateRangeBy: sortDateRangeBy && dateRange ? sortDateRangeBy : null,
        dateRange: sortDateRangeBy && dateRange ? dateRange : null,
        brokerId: profile.data?.getProfile.brokerId,
        showOrders: currentTab,
        page,
        itemsPerPage,
        sortBy,
        sortDirection,
        filter: filterState,
      },
    });

    clientsFilter(null);
    setLoading(false);
    setOrdersData(initialOrdersData.data?.getBrokerDashboardPackages.orders);
    setTotalPageCount(initialOrdersData.data?.getBrokerDashboardPackages.totalPageCount);
    setTotalPackagesCount(initialOrdersData.data?.getBrokerDashboardPackages?.totalPackagesCount);
    setLoading(false);
  };

  const handleClearFilters = async () => {
    setFilterState({});
    setSortDateRangeBy("saleDate");
    setDateRange(null);
    setLoading(true);

    const profile = await client.query({
      query: GET_BROKER_PROFILE,
      fetchPolicy: "cache-first",
    });

    const initialOrdersData = await client.query({
      query: GET_BROKER_DASHBOARD_DATA,
      variables: {
        sortDateRangeBy: null,
        dateRange: null,
        brokerId: profile.data?.getProfile.brokerId,
        showOrders: currentTab,
        page,
        itemsPerPage,
        sortBy,
        sortDirection,
        filter: {},
      },
      fetchPolicy: "cache-first",
    });

    clientsFilter(null);
    setLoading(false);
    setOrdersData(initialOrdersData.data?.getBrokerDashboardPackages.orders);
    setTotalPageCount(initialOrdersData.data?.getBrokerDashboardPackages.totalPageCount);
    setTotalPackagesCount(initialOrdersData.data?.getBrokerDashboardPackages?.totalPackagesCount);
    setLoading(false);
  };

  return (
    <>
      <NavbarTabs
        handleCurrentTab={handleCurrentTab}
        currentTab={currentTab ? currentTab : TABS_LABELS.NEW}
        currentSection={currentSection}
      />
      <Container className="mt-4" fluid>
        <Row className="align-items-center mb-4">
          <Col xs="4">
            <h1 className="mb-0">{currentTab}</h1>
          </Col>
        </Row>
        <TableLayout
          orders={ordersData}
          tableConfig={TABLE_CONFIG.BROKER_DASHBOARD}
          sortBy={sortBy}
          sortDirection={sortDirection}
          setSortBy={setSortBy}
          setSortDirection={setSortDirection}
          // setFilter={setFilter}
          filterState={filterState}
          setFilterState={setFilterState}
          isQueryLoading={loading}
          totalPageCount={totalPageCount}
          totalPackagesCount={totalPackagesCount}
          itemsPerPage={itemsPerPage}
          page={page}
          setPage={setPage}
          handleApplyFilters={handleApplyFilters}
          handleClearFilters={handleClearFilters}
          setSortDateRangeBy={setSortDateRangeBy}
          setDateRange={setDateRange}
          sortDateRangeBy={sortDateRangeBy}
          dateRange={dateRange}
          setItemsPerPage={setItemsPerPage}
        />
      </Container>
    </>
  );
};

export default PackageOrdersTable;

import { useApolloClient } from "@apollo/client";
import { Formik } from "formik";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { successMsg } from "../../cache/vars";
import { FORGOT_PASSWORD } from "../../queries/auth";

import "./forgotPassword.scss";

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email().required("Required"),
});

const ForgotPasswordPage: React.FC = () => {
  const client = useApolloClient();
  const history = useHistory();

  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg="5" md="7">
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 py-lg-5">
              <h1 className="forgotPassword-text">Forgot your password?</h1>
              <p className="forgotPassword-description">
                Enter your email and we will send you instructions to reset your password.
              </p>
              <div className="divider-red"></div>
              <Formik
                initialValues={{ email: "" }}
                onSubmit={async (userInfo) => {
                  const forgotPasswordResponse = await client.mutate({
                    mutation: FORGOT_PASSWORD,
                    variables: { email: userInfo.email },
                    fetchPolicy: "no-cache",
                  });

                  if (forgotPasswordResponse.data?.forgotPassword === "ok") {
                    history.push("/login");
                    successMsg("Link sent, please check your email");
                  }
                }}
                validationSchema={VALIDATION_SCHEMA}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;
                  return (
                    <Form role="form" onSubmit={handleSubmit}>
                      <FormGroup>
                        <FormText className="input-name" color="black">Email</FormText>
                        <Input
                          placeholder="Email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          name="email"
                          type="text"
                          invalid={!!errors.email && !!touched.email}
                        />
                        {errors.email && touched.email &&
                          <FormFeedback>Wrong email format</FormFeedback>
                        }
                      </FormGroup>
                      <div className="text-center">
                        <button
                          className="my-2 bg-forgotPassword-btn"
                          type="submit"
                          disabled={!dirty || isSubmitting}
                        >
                          Send Link
                        </button>
                        <Row className="d-flex justify-content-center">
                          <p className="link-paragraph">
                            Back to log in page?
                            <Link to="/login">
                              <small>Log in</small>
                            </Link>
                          </p>
                        </Row>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPasswordPage;
